import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { m, AnimatePresence } from 'framer-motion';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
// import { Draggable, Droppable, DragDropContext } from 'react-beautiful-dnd';

import axios from 'axios';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { alpha } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Tooltip, CircularProgress } from '@mui/material';

import { useGetToken } from 'src/hooks/useHandleSessions';

import { del } from 'src/server/http';
import { activeSnack } from 'src/server/store/common';
import { TOKEN_PREFIX } from 'src/server/http/axiosInstance';

import Iconify from 'src/components/iconify';
import { varFade } from 'src/components/animate';

import ImageDeleteError from './ImageDeleteError';
import RejectionFiles from './errors-rejection-files';

// ----------------------------------------------------------------------

export default function Upload({
  imagesPreview,
  setImagesPreview,
  extraFunction,
  autoWidth,
  single,
  folder,
}) {
  const dispatch = useDispatch();

  const uploadBaseUrl = '';
  // `${PROD_RESOURCE_BASE_URL}/resource/upload/form-data/${folder}`;
  const imageSize = 2;

  const { token } = useGetToken(TOKEN_PREFIX);

  function bytesToSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (bytes === 0) return '0 Byte';
    return {
      size: `${Math.round(bytes / 1024 ** i, 2)} ${sizes[i]}`,
      sizeInKb: Math.round(bytes / 1024),
    };
  }

  const [fileSizeIssue, setFileSizeIssue] = useState('');
  const [deleteErrorPopup, setDeleteErrorPopup] = useState({
    status: false,
    imageId: '',
  });
  const [uploadLoad, setUploadLoad] = useState(false);
  const [deleteLoad, setDeleteLoad] = useState({
    status: false,
    id: '',
  });

  const handleFileSelect = (acceptedFiles) => {
    setUploadLoad(true);

    const selectedFiles = acceptedFiles;

    const { size, sizeInKb } = bytesToSize(selectedFiles[0]?.size);

    if (sizeInKb >= imageSize * 1024) {
      setFileSizeIssue(`${size} file size is too large - Max Limit ${imageSize}MB`);
    } else {
      setFileSizeIssue('');
      uploadingImage(selectedFiles);
    }

    setUploadLoad(false);
  };

  const uploadingImage = (imageFile) => {
    console.log('img', imageFile);
    const a = imageFile[0];

    const formData = new FormData();
    console.log('Type of imageFile:', imageFile instanceof Blob ? 'Blob' : 'Not Blob');

    formData.append('file', a, a.name);
    setUploadLoad(true);
    axios
      .post(uploadBaseUrl, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res?.data?.data?.url);

        // show responsce
        setUploadLoad(false);
        // setImagesPreview([res?.data?.data] || []);
        setImagesPreview([...(imagesPreview || []), res?.data?.data]);

        // setImagesPreview( res?.data?.data?.url);

        dispatch(activeSnack({ type: 'success', message: 'Uploaded SuccessFully' }));
        // handleClose();
      })
      .catch((error) => {
        setUploadLoad(false);
        dispatch(activeSnack({ type: 'error', message: 'FileUpload Fail' }));
      });
    // imageFile.forEach((item) => {
    //   const reader = new FileReader();
    //   reader.onload = async () => {
    //     if (reader.readyState === 2) {
    //       try {
    //         setUploadLoad(true);
    //         const res = await post(uploadBaseUrl, { file: reader.result }, {}, 'resource');
    //         console.log('img', res);
    //         if (res) {
    //           setImagesPreview([...(imagesPreview || []), res]);
    //           dispatch(activeSnack({ type: 'success', message: 'Uploaded SuccessFully' }));
    //           setUploadLoad(false);
    //         }
    //       } catch (error) {
    //         console.log(error);

    //         setUploadLoad(false);
    //         dispatch(activeSnack({ type: 'error', message: 'Copy Fail' }));
    //       }
    //     }
    //   };
    //   reader.readAsDataURL(item);
    // });
  };

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: true,
    onDrop: handleFileSelect,
  });

  const hasFiles = !!imagesPreview && !!imagesPreview.length;

  const hasError = isDragReject;

  const handleRemove = async (id) => {
    try {
      setDeleteLoad({
        status: true,
        id,
      });
      const res = await del(`/resource/${id}`, {}, 'resource');

      console.log(res);

      if (res?.isSuccess) {
        setImagesPreview(imagesPreview?.filter((img) => img.public_id !== id));
        dispatch(activeSnack({ type: 'success', message: 'Image removed' }));
      } else {
        dispatch(activeSnack({ type: 'error', message: 'Image delete fail, try again' }));
      }

      if (res?.status === 2001 && res?.message === 'File not found!') {
        setDeleteErrorPopup({
          status: true,
          imageId: id,
        });
      }

      setDeleteLoad({
        status: false,
        id,
      });
    } catch (error) {
      setDeleteLoad({
        status: false,
        id,
      });
      dispatch(activeSnack({ type: 'error', message: 'Image delete fail, try again' }));
    }
  };

  // const onDragEnd = (result) => {
  //   const { destination, source } = result;

  //   if (!destination) return;

  //   const reorderedItems = Array.from(imagesPreview);
  //   const [movedItem] = reorderedItems.splice(source.index, 1);
  //   reorderedItems.splice(destination.index, 0, movedItem);

  //   setImagesPreview(reorderedItems);
  // };

  const handleDeleteFromLocal = () => {
    setImagesPreview(imagesPreview?.filter((img) => img.public_id !== deleteErrorPopup?.imageId));
    setDeleteErrorPopup({
      status: false,
      imageId: '',
    });
  };

  const moveImage = (fromIndex, toIndex) => {
    const updatedImages = [...imagesPreview];
    const [movedImage] = updatedImages.splice(fromIndex, 1);
    updatedImages.splice(toIndex, 0, movedImage);
    setImagesPreview(updatedImages);
  };

  return (
    <Stack>
      {deleteErrorPopup?.status && (
        <ImageDeleteError
          setOpen={setDeleteErrorPopup}
          open={deleteErrorPopup}
          handleDeleteFromLocal={handleDeleteFromLocal}
        />
      )}
      <Stack
        spacing={2}
        sx={{
          mt: single && 0,
          width: 1,
          position: 'relative',
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {hasFiles && (
          <>
            {/* <Stack
              spacing={2}
              sx={{
                my: single ? 0 : 3,
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'start',
              }}
            >
              <AnimatePresence initial={false}>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable-chips">
                    {(provided) => (
                      <Stack
                        direction="row"
                        spacing={1}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        sx={{ flexWrap: 'wrap' }}
                      >
                        {imagesPreview?.map((img, key) => (
                          <Draggable key={img.public_id} draggableId={img.public_id} index={key}>
                            {(dragProvided) => (
                              <div
                                ref={dragProvided.innerRef}
                                {...dragProvided.draggableProps}
                                {...dragProvided.dragHandleProps}
                              >
                                <Stack
                                  key={key}
                                  component={m.div}
                                  {...varFade().inUp}
                                  spacing={2}
                                  direction="row"
                                  alignItems="center"
                                  sx={{
                                    my: single ? 0 : 1,
                                    py: 1,
                                    px: 1.5,
                                    // width: '180px',
                                    width: autoWidth ? 'auto' : '180px',
                                    height: autoWidth ? '193px' : '180px',
                                    position: 'relative',
                                    borderRadius: 1,
                                    backgroundColor: '#f7f7f8',
                                  }}
                                >
                                  <Button
                                    onClick={() =>
                                      extraFunction({
                                        imgData: img,
                                        status: true,
                                      })
                                    }
                                    color="inherit"
                                    sx={{
                                      // width: '180px',
                                      width: autoWidth ? 'auto' : '180px',

                                      height: autoWidth ? '193px' : '180px',
                                      backgroundColor: 'transparent',
                                      '&:hover': { backgroundColor: 'transparent' },
                                    }}
                                  >
                                    <img
                                      alt={img?.public_id}
                                      src={img?.url}
                                      // todo
                                      style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'contain',
                                        borderRadius: '15px',
                                        filter:
                                          deleteLoad?.status && deleteLoad?.id === img?.public_id
                                            ? 'grayscale(1)'
                                            : 'none',
                                      }}
                                    />
                                  </Button>
                                  <Stack
                                    sx={{
                                      position: 'absolute',
                                      top: -8,
                                      right: -8,
                                      backgroundColor: 'rgba(0, 0, 0, 0.48)',
                                      height: '25px',
                                      width: '25px',
                                      borderRadius: '50%',
                                    }}
                                    alignItems="center"
                                    justifyContent="center"
                                  >
                                    {deleteLoad?.status && deleteLoad?.id === img?.public_id ? (
                                      <CircularProgress size={14} sx={{ mx: 1 }} />
                                    ) : (
                                      <IconButton
                                        onClick={() => handleRemove(img?.public_id)}
                                        sx={{
                                          color: '#fff',
                                          backdropFilter: 'blur( 4px )',
                                          backgroundColor: 'rgba(0, 0, 0, 0.48)',
                                          '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.48)' },
                                        }}
                                        size="small"
                                      >
                                        <Iconify icon="mingcute:close-line" width={16} />
                                      </IconButton>
                                    )}
                                  </Stack>
                                </Stack>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </Stack>
                    )}
                  </Droppable>
                </DragDropContext>
              </AnimatePresence>
            </Stack> */}
            <AnimatePresence initial={false}>
              <DndProvider backend={HTML5Backend}>
                <div style={{ display: 'flex', gap: '10px' }}>
                  {imagesPreview?.map((image, index) => (
                    <ImageItem
                      key={index}
                      image={image}
                      index={index}
                      moveImage={moveImage}
                      single={single}
                      autoWidth={autoWidth}
                      extraFunction={extraFunction}
                      deleteLoad={deleteLoad}
                      handleRemove={handleRemove}
                    />
                  ))}
                </div>
              </DndProvider>
            </AnimatePresence>
          </>
        )}

        {single && imagesPreview?.length === 1 ? (
          ''
        ) : (
          <Box
            {...getRootProps()}
            sx={{
              p: 5,
              width: autoWidth ? 'auto' : '180px',
              height: autoWidth ? '193px' : '180px',
              outline: 'none',
              borderRadius: 1,
              cursor: 'pointer',
              overflow: 'hidden',
              position: 'relative',
              bgcolor: (theme) => alpha(theme.palette.grey[500], 0.08),
              border: (theme) => `1px dashed ${alpha(theme.palette.grey[500], 0.2)}`,
              transition: (theme) => theme.transitions.create(['opacity', 'padding']),
              '&:hover': {
                opacity: 0.72,
              },
              ...(isDragActive && {
                opacity: 0.72,
              }),
              // ...(disabled && {
              //   opacity: 0.48,
              //   pointerEvents: 'none',
              // }),
              ...(hasError && {
                color: 'error.main',
                borderColor: 'error.main',
                bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
              }),
            }}
          >
            <input {...getInputProps()} />

            <Stack
              height="100%"
              spacing={3}
              alignItems="center"
              justifyContent="center"
              flexWrap="wrap"
            >
              <Stack spacing={0.5} alignItems="center" sx={{ color: 'text.disabled' }}>
                <Iconify icon="eva:cloud-upload-fill" width={40} />
                <Typography variant="body2" textAlign="center">
                  Drop files or click to browse
                </Typography>
                {uploadLoad && <CircularProgress size={14} sx={{ mx: 1 }} />}
              </Stack>
            </Stack>
          </Box>
        )}

        <RejectionFiles fileRejections={fileRejections} />
      </Stack>

      <Typography variant="caption" color="red">
        {fileSizeIssue}
      </Typography>
    </Stack>
  );
}

Upload.propTypes = {
  imagesPreview: PropTypes.any,
  setImagesPreview: PropTypes.any,
  extraFunction: PropTypes.any,
  autoWidth: PropTypes.any,
  single: PropTypes.bool,
  folder: PropTypes.any,
};

const ItemType = 'IMAGE';

const ImageItem = ({
  image,
  index,
  moveImage,
  single,
  autoWidth,
  extraFunction,
  deleteLoad,
  handleRemove,
}) => {
  const [, ref] = useDrag({
    type: ItemType,
    item: { index },
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveImage(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <div ref={(node) => ref(drop(node))} style={{ padding: '5px' }}>
      <Stack
        key={index}
        component={m.div}
        {...varFade().inUp}
        spacing={2}
        direction="row"
        alignItems="center"
        sx={{
          my: single ? 0 : 1,
          py: 1,
          px: 1.5,
          // width: '180px',
          width: autoWidth ? 'auto' : '180px',
          height: autoWidth ? '193px' : '180px',
          position: 'relative',
          borderRadius: 1,
          backgroundColor: '#f7f7f8',
          cursor: 'grab',
        }}
        ref={(node) => ref(drop(node))}
      >
        {/* <Button
          onClick={() =>
            extraFunction({
              imgData: image,
              status: true,
            })
          }
          color="inherit"
          sx={{
            // width: '180px',
            width: autoWidth ? 'auto' : '180px',

            height: autoWidth ? '193px' : '180px',
            backgroundColor: 'transparent',
            '&:hover': { backgroundColor: 'transparent' },
          }}
        > */}
        <img
          alt={image?.public_id}
          src={image?.url}
          // todo
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            borderRadius: '15px',
            filter:
              deleteLoad?.status && deleteLoad?.id === image?.public_id ? 'grayscale(1)' : 'none',
          }}
        />
        {/* </Button> */}
        <Stack
          sx={{
            position: 'absolute',
            top: -8,
            right: -8,
            backgroundColor: 'rgba(0, 0, 0, 0.48)',
            height: '25px',
            width: '25px',
            borderRadius: '50%',
          }}
          alignItems="center"
          justifyContent="center"
        >
          {deleteLoad?.status && deleteLoad?.id === image?.public_id ? (
            <CircularProgress size={14} sx={{ mx: 1 }} />
          ) : (
            <Tooltip title="Remove image">
              <IconButton
                onClick={() => handleRemove(image?.public_id)}
                sx={{
                  color: '#fff',
                  backdropFilter: 'blur( 4px )',
                  backgroundColor: 'rgba(0, 0, 0, 0.48)',
                  '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.48)' },
                }}
                size="small"
              >
                <Iconify icon="mingcute:close-line" width={16} />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
        <Stack
          sx={{
            position: 'absolute',
            top: -8,
            right: 25,
            backgroundColor: 'rgba(0, 0, 0, 0.48)',
            height: '25px',
            width: '25px',
            borderRadius: '50%',
          }}
          alignItems="center"
          justifyContent="center"
        >
          <Tooltip title="Edit alt text">
            <IconButton
              onClick={() =>
                extraFunction({
                  imgData: image,
                  status: true,
                })
              }
              sx={{
                color: '#fff',
                backdropFilter: 'blur( 4px )',
                backgroundColor: 'rgba(0, 0, 0, 0.48)',
                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.48)' },
              }}
              size="small"
            >
              <Iconify icon="solar:pen-bold" width={16} />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    </div>
  );
};

ImageItem.propTypes = {
  image: PropTypes.any,
  index: PropTypes.any,
  moveImage: PropTypes.any,
  single: PropTypes.any,
  autoWidth: PropTypes.any,
  extraFunction: PropTypes.any,
  deleteLoad: PropTypes.any,
  handleRemove: PropTypes.any,
};
